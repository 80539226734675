import React from "react";
import logo from "../images/recyclingSymbol.png";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../index.css";

const Header = (props) => {
  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand centerRow" href="/">
          <span
            style={{
              fontSize: "36px",
              color: "#e33625",
              fontFamily: "New Century Schoolbook",
            }}
          >
            SFM
          </span>{" "}
          <img src={logo} className="logo" alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/recyclables">
                Recyclables
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/scrapPreparation">
                Scrap Preparation
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/containerService">
                Container Service
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
