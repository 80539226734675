import React from "react";
import rollOff from "../../images/rollOffContainer.png";
import "../../index.css";

const ContainerService = () => {
  return (
    <div className="img2 centerChildren">
      <h1>Container Service Details </h1>
      <h4>Need a Bin?</h4>
      <p className="text">We offer bin rental, pickup, and dropoff!</p>
      <p className="text">
        Need a bin for Metal? No problem! Need a bin for trash? We've got you
        covered! Just fill the bin, and notify us once it's full.
      </p>
      <img src={rollOff} alt="roll Off Bin" className="img" />
      <h4>Pricing and Rates</h4>
      <p className="text">1x Roll Off Bin: Call for Pricing</p>
      <p className="text">
        Bins full of Metal will be paid the current Board rate for the recycled
        Metal.
      </p>
      <p className="text">Bins for trash will charge an additional dump fee.</p>
      <p className="text">
        Bins can be rented for months, or as short as a day.
      </p>
      <p className="text">
        *Bin Sizes, Metal Board Prices, and Dump Fees subject to change.
      </p>
      <h4>Request a Bin</h4>
      <p className="text">Contact: SFM Office: 801-471-4679</p>
    </div>
  );
};

export default ContainerService;
