import React from "react";
import nonferrousMetals from "../../utils/nonferrousMetals";
import "../../index.css";

const NonFerrousGrid = () => {
  return (
    <div className="grid">
      {nonferrousMetals.map((m) => (
        <a
          href="/"
          key={m.name}
          style={{
            backgroundImage: `url(${m.image})`,
          }}
          className="brighten"
        >
          {m.name}
        </a>
      ))}
    </div>
  );
};

export default NonFerrousGrid;
