import React from "react";
import homePageVideo from "../../videos/SFMvideo.mp4";

const OverlaidVideo = () => {
  return (
    <div className="videoMain">
      <div className="videoOverlay"></div>
      <div className="content">
        <h1 style={{ fontWeight: "Bold" }}>Turn Metal Into Money</h1>
        <p style={{ fontWeight: "Bold" }}>Recycle your metal and get paid</p>
        <form method="get" action="/recyclables">
          <button type="submit">Learn More</button>
        </form>
      </div>
      <video src={homePageVideo} autoPlay playsInline loop muted />
    </div>
  );
};

export default OverlaidVideo;
