import React from "react";
import "../../index.css";

const OpenHours = () => {
  return (
    <div className="centerChildren">
      <h4 className="textGreen">Hours</h4>
      <p className="text">Mon-Fri 8am - 5pm • Sat 9am - 2pm • Sun - closed</p>
      <p className="text">
        Please arrive at least 30 minutes before closing, so we have sufficient
        time to assist you before we close.
      </p>
    </div>
  );
};

export default OpenHours;
