import React from "react";
import FerrousGrid from "../common/ferrousGrid";
import NonFerrousGrid from "../common/nonferrousGrid";
import "../../index.css";

const ScrapPreparation = () => {
  return (
    <div className="centerChildren">
      <br></br>
      <h1>How can I get the most out of my Scrap?</h1>
      <p className="text">
        Preparing your metals is a great way to make sure you get top dollar!
        <br /> Here are some general rules and things to watch out for:
      </p>
      <h4>Sort Your Metals</h4>
      <p className="text">
        Mixed metals can contaminate your main product. <br />
        keep each metal separated from other metals.
      </p>
      <h4>Remove Contaminants</h4>
      <p className="text">
        Bits of Plastic, Rubber, or Steel can contaminate your product. <br />{" "}
        Check for: Screws, Staples, Fittings, Weather Stripping, Rubber
        caulking, insulation etc.
      </p>
      <h4>Things To Watch For</h4>
      <p className="text">
        With certain metals such as Copper, heavy oxidization, wire guage, and
        solder joints can also affect the grade and price. <br /> <br /> With
        Aluminum Cans, Please keep Tin/Aluminum foil, and cat food cans separate
        from Soda cans, as these are different grades and will affect the price.
      </p>
      <div className="border"></div>
      <h2>Ferrous Metals</h2>
      <p className="text">
        Metals that mainly comprise of Iron or that are magnetic (Iron's atomic
        symbol is "Fe", as in "Ferrous") Commonly including:
      </p>
      <FerrousGrid />
      <h2>Non-Ferrous Metals</h2>
      <p className="text">
        Metals not mainly comprised of Iron or that are non-magnetic. Commonly
        including:
      </p>
      <NonFerrousGrid />
    </div>
  );
};

export default ScrapPreparation;
