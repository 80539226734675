import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import LargeDisplay from "../common/largeDisplay";
import OpenHours from "../common/openHours";
import HomeGrid from "../common/homeGrid";
import "../../index.css";
import OverlaidVideo from "../common/overlaidVideo";

const Home = () => {
  return (
    <div>
      <OverlaidVideo />
      <LargeDisplay />
      <div className="container px-4 py-5" id="custom-cards">
        <div className="border"></div>
        <OpenHours />
        <div className="border"></div>
        <HomeGrid />
        <div className="border-top mt-5"></div>
      </div>
    </div>
  );
};

export default Home;
