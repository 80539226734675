import React from "react";

const homeGridCell = ({ name, href, image, overlayRed = "" }) => {
  return (
    <a
      href={href}
      key={name}
      style={{
        backgroundImage: `url(${image})`,
      }}
      className="brighten"
    >
      <div className={`overlay${overlayRed}`}>
        <p>{name}</p>
      </div>
    </a>
  );
};

export default homeGridCell;
