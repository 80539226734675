import Copper from "../images/nonferrous/copper.jpg";
import Brass from "../images/nonferrous/brass.jpg";
import Aluminum from "../images/nonferrous/aluminum.jpg";
import Stainless from "../images/nonferrous/stainless.jpg";
import Lead from "../images/nonferrous/leadBattery.jpg";
import Zinc from "../images/nonferrous/zinc.jpg";
import Titanium from "../images/nonferrous/titanium.jpg";
import Tungsten from "../images/nonferrous/tungsten.jpg";

const nonferrousMetals = [
  { name: "Copper", image: Copper },
  { name: "Brass", image: Brass },
  { name: "Aluminum", image: Aluminum },
  { name: "Stainless Steel", image: Stainless },
  { name: "Lead", image: Lead },
  { name: "Zinc", image: Zinc },
  { name: "Titanium", image: Titanium },
  { name: "Tungsten", image: Tungsten },
  { name: "Electric Motors & Misc", image: null },
];

export default nonferrousMetals;
