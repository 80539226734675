import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/header";
import Home from "./components/pages/home";
import Footer from "./components/footer";
import Recyclables from "./components/pages/recyclables";
import ScrapPreparation from "./components/pages/scrapPreparation";
import ContainerService from "./components/pages/containerService";
import NotFound from "./components/pages/notFound";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recyclables" element={<Recyclables />} />
          <Route path="/scrapPreparation" element={<ScrapPreparation />} />
          <Route path="/containerService" element={<ContainerService />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
