import React from "react";
import logo from "../images/recyclingSymbol.png";

const Footer = () => {
  return (
    <footer className="container">
      <ul className="nav justify-content-center border-bottom pb-3 mb-3">
        <li className="nav-item">
          <a href="/recyclables" className="nav-link px-2 text-muted">
            Recyclables
          </a>
        </li>
        <li className="nav-item">
          <a href="/scrapPreparation" className="nav-link px-2 text-muted">
            Scrap Preparation
          </a>
        </li>
        <li className="nav-item">
          <a href="/containerService" className="nav-link px-2 text-muted">
            Container Service
          </a>
        </li>
      </ul>
      <a
        href="/"
        className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
      >
        <img className="logo" src={logo} alt="logo" />
      </a>
      <p className="text-center text-muted">© 2022 SFM Recycling, Inc</p>
    </footer>
  );
};

export default Footer;
