import React from "react";
import background from "../../images/SFMbackground.png";
import "../../index.css";

const LargeDisplay = () => {
  return (
    <div className="largeDisplay">
      <img src={background} alt="SFM" className="img" />
    </div>
  );
};

export default LargeDisplay;
