import React from "react";
import HomeGridCell from "./homeGridCell";
import Background from "../../images/background.jpg";
import Bin from "../../images/rollOffContainer.png";
import WhatToRecycle from "../../images/whatToRecycle.jpg";
import "../../index.css";

const HomeGrid = () => {
  return (
    <div>
      <div className="homeGrid">
        <HomeGridCell
          name={"What Recyclables Can I Bring?"}
          href={"/recyclables"}
          image={WhatToRecycle}
        />
        <HomeGridCell
          name={"Processing a lot of Metal? Request a Bin!"}
          href={"/containerService"}
          image={Bin}
        />
      </div>
      <div className="homeGridBottom">
        <HomeGridCell
          name={"How To Get The Most Money For Your Metal"}
          href={"/scrapPreparation"}
          image={Background}
          overlayRed="Red"
        />
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.488763930587!2d-111.82481228501378!3d39.99752878895204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x874da7bac7729ac3%3A0xc228e4806761cf33!2s96%20N%20State%20St%2C%20Genola%2C%20UT%2084655!5e0!3m2!1sen!2sus!4v1651115649147!5m2!1sen!2sus"
          // width="600"
          // height="450"
          style={{ border: "0", height: "100%", width: "100%" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default HomeGrid;
