import Steel from "../images/ferrous/nutsAndBolts.jpg";
import Iron from "../images/ferrous/iron.jpg";
import Tin from "../images/ferrous/tin.jpg";

const ferrousMetals = [
  { name: "Steel", image: Steel },
  { name: "Iron", image: Iron },
  { name: "Tin", image: Tin },
];

export default ferrousMetals;
