import React from "react";
import FerrousGrid from "../common/ferrousGrid";
import NonFerrousGrid from "../common/nonferrousGrid";

const Recyclables = () => {
  return (
    <div>
      <h1 className="text">What Can I Recycle?</h1>
      <p className="text">
        We accept all types of Non-Hazardous Metals. Metals are generally
        processed in 2 categories
      </p>
      <h2 className="text">Ferrous Metals</h2>
      <p className="text">
        Metals that mainly comprise of Iron or are Magnetic (Iron's atomic
        symbol is "Fe", as in "Ferrous") Commonly including:
      </p>
      <FerrousGrid />
      <h2 className="text">Non-Ferrous Metals</h2>
      <p className="text">
        Metals not mainly comprised of Iron. Commonly including:
      </p>
      <NonFerrousGrid />
    </div>
  );
};

export default Recyclables;
